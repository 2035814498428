import { useLayoutEffect, useRef, useState } from 'react';

import { SecureStorage } from '@/plugin/crypto';
import { geDoubleDigits } from '@/utils/numberUtils';

const isMobile = /Mobi/i.test(window.navigator.userAgent);

const startDate: any = new Date(); //타이머 시작 시간 설정
const EXPIRATION_TIME = 3 * 60 * 60 * 1000; //3시간(밀리초)

export default function ExpirationTime() {
	const userStorage = new SecureStorage(sessionStorage).getItem('user-storage', 'user-storage');
	const { member_token: memberToken } = userStorage;

	const [remainingHours, setRemainingHours] = useState(2);
	const [remainingMinutes, setRemainingMinutes] = useState(59);
	const [remainingSeconds, setRemainingSeconds] = useState(59);

	const timerRef = useRef<NodeJS.Timeout | null>(null); // 타이머 참조를 위한 useRef

	const timerCallback = () => {
		const nowDate: any = new Date();
		const diffTime = nowDate - startDate;
		const remainingTime = EXPIRATION_TIME - diffTime;

		if (remainingTime <= 0) {
			// 3시간이 지나면 로그아웃 처리
			if (timerRef.current) clearInterval(timerRef.current);
			sessionStorage.removeItem('user-storage');

			if (!isMobile) {
				location.href = '/login';
			} else {
				location.href = `/login?firebaseToken=${memberToken}`;
			}
			return;
		}

		// 남은 시간 계산
		setRemainingHours(Math.floor(remainingTime / (1000 * 60 * 60)));
		setRemainingMinutes(Math.floor((remainingTime / (1000 * 60)) % 60));
		setRemainingSeconds(Math.floor((remainingTime / 1000) % 60));
	};

	useLayoutEffect(() => {
		timerRef.current = setInterval(timerCallback, 1000); //1초마다 업데이트

		return () => {
			if (timerRef.current) clearInterval(timerRef.current); //컴포넌트 언마운트시에 타이머 클리어
		};
	}, []);

	return (
		<div className="navbar-item">
			<div className="badge badge-default badge-sm">
				{geDoubleDigits(remainingHours)}:{geDoubleDigits(remainingMinutes)}:{geDoubleDigits(remainingSeconds)}
			</div>
		</div>
	);
}
